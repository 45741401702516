import {
  DocumentReference,
  collection,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../hooks/firestore";
import { atom, selector, selectorFamily } from "recoil";
import { DocumentSnapshot } from "@google-cloud/firestore";
import { FolderMapping } from "./types/folder";

type Folder = {
  name: string;
  id: string;
  parent?: DocumentReference<Folder>;
  parentId: string;
};

export const folder = atom<FolderMapping>({
  key: "folder",
  default: null,
});

const resolveParents = (folders: any, folderId: string) => {
  if (!folderId) return;
  const updated = { ...folders[folderId] };
  updated.id = folderId;
  if (folders[folderId].parentId) {
    updated.parent = resolveParents(folders, updated.parentId);
    // delete updated.parentId;
  }
  return updated;
};

export const folderStructure = selector({
  key: "CurrentFolder",
  get: async () => {
    const document = await getDocs(collection(db, "folders"));

    const folders: { [key: string]: Folder } = {};
    document.forEach((doc) => {
      const data = doc.data() as Folder;
      if (data.parent) data.parentId = data.parent.id;
      delete data.parent;
      folders[doc.id] = data;
    });
    return folders;
  },
});

export const currentFolder = selectorFamily({
  key: "CurrentFolder",
  get:
    (folderId: string) =>
    async ({ get }) => {
      const folders = get(folderStructure);
      //   const folder = [folderId];
      return resolveParents(folders, folderId);

      // return resolveParents(document.data() as Folder);
    },
});

export const getFoldersByFolder = selectorFamily({
  key: "getFoldersByFolder",
  get:
    (id) =>
    ({ get }) => {
      const allFolders = get(folder) || {};

      return Object.values(allFolders).filter((f) => f.parentId === id);
    },
});
