import { atom, selectorFamily } from "recoil";
import { Movement, MovementMapping } from "./types/movements";

export const movements = atom<MovementMapping>({
  key: "movements",
  default: null,
});

export const getMovementsByFolder = selectorFamily({
  key: "getMovementsByFolder",
  get:
    (id) =>
    ({ get }) => {
      const allMovements = get(movements) || {};

      return Object.values(allMovements).filter(
        (movement) => movement.parentId === id
      );
    },
});
