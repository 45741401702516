import * as React from "react";
import Box from "@mui/material/Box";
import MUIDrawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useNavigate } from "react-router-dom";
import { Settings } from "@mui/icons-material";

const Drawer = (props: {
  open: boolean;
  onClose: () => void;
  isDesktop: boolean;
  width: number;
}) => {
  const isMyStages = window.location.pathname.startsWith("/stages");
  const navigate = useNavigate();
  return (
    <>
      <MUIDrawer
        variant={props.isDesktop ? "persistent" : "temporary"}
        open={props.open}
        onClose={props.onClose}
        sx={{
          width: props.width,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: props.width,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {[
              {
                text: "My Stages",
                onClick: () => {
                  navigate("/stages");
                },
                icon: <LibraryMusicIcon />,
                active: isMyStages,
              },
              {
                text: "Shared with me",
                onClick: () => {},
                icon: <FolderSharedIcon />,
                active: false,
              },
              {
                text: "Engagement",
                onClick: () => {},
                icon: <TimelineIcon />,
                active: false,
              },
            ].map((item, index) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton selected={item.active} onClick={item.onClick}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {["Settings"].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </MUIDrawer>
    </>
  );
};

export default Drawer;
