import React from "react";
import { db } from "../hooks/firestore";
import { collection, getDocs } from "firebase/firestore";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentFolder, folder } from "../store/folders";
import { Breadcrumbs, Typography } from "@mui/material";
import CollectionAccessor from "../hooks/folders";
import { FolderMapping } from "../store/types/folder";
import { Link, useNavigation, useParams } from "react-router-dom";
import FolderSelect from "./FolderSelect";
import { MovementMapping } from "../store/types/movements";
import { movements } from "../store/movements";

const Folder = (props: any) => {
  let { id } = useParams<{ id: string }>();

  const [folderStructure, setFolderStructure] =
    useRecoilState<FolderMapping>(folder);
  const [_, setMovements] = useRecoilState<MovementMapping>(movements);

  React.useEffect(() => {
    const folders = new CollectionAccessor("folders", setFolderStructure);
    const movements = new CollectionAccessor("movements", setMovements);
    folders.subscribe();
    movements.subscribe();
  }, []);

  if (!folderStructure) {
    return <Typography>Loading</Typography>;
  }

  let structure: { name: string; id: string | null }[] = [
    { name: "My Stages", id: "" },
  ];
  if (id) {
    structure = [];
    if (folderStructure[id]) {
      let current = folderStructure[id];
      do {
        structure.push({ name: current.name, id: current.id });
        if (current.parentId) current = folderStructure[current.parentId];
        else break;
      } while (1);
    }
    structure.push({ name: "My Stages", id: "" });
    structure.reverse();
  }
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        {structure.slice(0, -1).map((folder) => (
          <Link
            style={{ color: "black", textDecoration: "none" }}
            to={folder.id ? `/stages/${folder.id}` : "/stages"}
            key={folder.id}
          >
            {folder.name}
          </Link>
        ))}
        <Typography color="text.primary">
          {structure.length === 0 ? "" : structure[structure.length - 1].name}
        </Typography>
      </Breadcrumbs>
      <FolderSelect />
    </>
  );
};

export default Folder;
