import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useRecoilState, useRecoilValue } from "recoil";
import { Link, useNavigate, useNavigation, useParams } from "react-router-dom";
import { getFolderContents } from "../store/selectors/currentFolder";
import { Stack, Typography } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";

export default function FolderSelect() {
  let { id } = useParams<{ id: string }>();
  const contents = useRecoilValue(getFolderContents(id));
  const nav = useNavigate();

  const rows = [
    ...Object.values(contents.folders).sort((a, b) =>
      a.name > b.name ? 1 : -1
    ),
    ...Object.values(contents.movements).sort((a, b) =>
      a.name > b.name ? 1 : -1
    ),
  ];

  if (rows.length === 0) {
    return (
      <div style={{ marginTop: 16 }}>
        <Typography>No stages found.</Typography>
      </div>
    );
  }

  return (
    <TableContainer component={Paper} style={{ marginTop: 16 }}>
      <Table aria-label="simple table">
        {/* <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead> */}
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              onClick={() => {
                nav(`/stages/${row.id}`);
              }}
              hover
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Stack direction="row" alignItems="center" gap={2}>
                  {row.type === "folder" ? <FolderIcon /> : null}
                  <Typography variant="body1">{row.name}</Typography>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
