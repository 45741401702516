import { selectorFamily } from "recoil";
import { folderStructure, getFoldersByFolder } from "../folders";
import { getMovementsByFolder, movements } from "../movements";
import { Movement } from "../types/movements";
import { Folder } from "../types/folder";

export const getFolderContents = selectorFamily({
  key: "currentFolder",
  get:
    (folderId: string | undefined) =>
    ({ get }) => {
      const folders = Object.values(
        get(getFoldersByFolder(folderId)) || {}
      ).map((f) => ({ ...f, type: "folder" }));
      const movements = Object.values(
        get(getMovementsByFolder(folderId)) || {}
      ).map((m) => ({ ...m, type: "song" }));

      return { folders, movements };
    },
});
