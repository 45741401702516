import { SignIn, SignUp } from "@clerk/clerk-react";
import { Grid, Paper } from "@mui/material";
import React from "react";

const Auth = ({
  signIn = false,
  signUp = false,
}: {
  signIn?: boolean;
  signUp?: boolean;
}) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        {signIn && <SignIn signUpUrl="/sign-up" afterSignInUrl={"/stages"} />}
        {signUp && <SignUp signInUrl="/sign-in" afterSignUpUrl={"/stages"} />}
      </Grid>
    </Grid>
  );
};

export default Auth;
