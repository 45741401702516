import { useAuth } from "@clerk/clerk-react";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import firebase, { initializeApp, getApp } from "firebase/app";
import Firestore, { getFirestore } from "firebase/firestore";
import React, { ReactNode, useEffect, useState } from "react";

const app = initializeApp({
  apiKey: "AIzaSyBw6Pst6sF121UJaj40sf3PgxjDcZ_Z1ZI",
  authDomain: "stagesync.firebaseapp.com",
  databaseURL: "https://stagesync-default-rtdb.firebaseio.com",
  projectId: "stagesync",
  storageBucket: "stagesync.appspot.com",
  messagingSenderId: "395999856983",
  appId: "1:395999856983:web:06a620781651280560d612",
});

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

// const withFirestore = (Wrapped: React.ComponentType<any>) => {
//   const [creds, setCreds] = useState(null);
//   const [firestore, setFirestore] = useState<Firestore.Firestore | null>(null);

//   useEffect(() => {
//     (async () => {
      

//       setFirestore(db);
//     })();
//   }, []);

//   if (!firestore) return () => null;

//   return () => <Wrapped db={firestore} />;
// };

// export default withFirestore;
