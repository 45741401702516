import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Firestore } from "@google-cloud/firestore";
import {
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  SignIn,
  SignUp,
  useAuth,
} from "@clerk/clerk-react";
import { Navigate, Route, Routes } from "react-router-dom";
import Folder from "./screens/Folder";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import PageWrapper from "./components/PageWrapper";

const protect = (child: JSX.Element) => {
  return (
    <>
      <SignedIn>
        <PageWrapper>{child}</PageWrapper>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
};

function App() {
  const auth = useAuth();
  const [isSignedIn, setIsSignedIn] = React.useState(false);

  React.useEffect(() => {
    if (auth.isSignedIn) {
      (async () => {
        const token = await auth.getToken({
          template: "integration_firebase",
        });

        if (!token) return;

        const authProvider = getAuth();

        await signInWithCustomToken(authProvider, token);
        setIsSignedIn(true);
      })();
    }
  }, [auth.isSignedIn]);

  if (!auth.isSignedIn) return <RedirectToSignIn />;
  if (!isSignedIn) return <></>;

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/stages" />} />
      <Route path="/stages/:id?" element={protect(<Folder />)} />
    </Routes>
  );
}

export default App;
