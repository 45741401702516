import React, { PropsWithChildren } from "react";
import Header from "./Header";
import Drawer from "./Drawer";
import { styled } from "@mui/material";
import { useWindowWidth } from "@react-hook/window-size";

const drawerWidth = 280;
const noop = () => undefined;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
  isDesktop: boolean;
}>(({ theme, open, isDesktop }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open &&
    isDesktop && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    }),
}));

const PageWrapper = (props: PropsWithChildren) => {
  const width = useWindowWidth();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const isDesktop = width > 800;

  React.useEffect(() => {
    if (isDesktop) setDrawerOpen(true);
    if (!isDesktop) setDrawerOpen(false);
  }, [isDesktop]);

  return (
    <>
      <Header
        isDesktop={isDesktop}
        onOpenDrawer={isDesktop ? noop : () => setDrawerOpen((o) => !o)}
      />
      <Drawer
        width={drawerWidth}
        isDesktop={isDesktop}
        onClose={isDesktop ? noop : () => setDrawerOpen(false)}
        open={drawerOpen}
      />
      <Main open={drawerOpen} isDesktop={isDesktop}>
        <div style={styles.container}>{props.children}</div>
      </Main>
    </>
  );
};

export default PageWrapper;

const styles = {
  container: {
    // padding: 24,
    // marginLeft: 240,
    marginTop: 62,
  },
};
