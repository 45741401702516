import {
  Unsubscribe,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "./firestore";
import { SetterOrUpdater } from "recoil";

class CollectionAccessor {
  #collection: string;
  #set: SetterOrUpdater<unknown>;
  #unsubscribe: Unsubscribe | undefined;
  constructor(collection: string, set: any) {
    this.#collection = collection;
    this.#set = set;
  }

  async subscribe() {
    const q = query(collection(db, this.#collection));

    const folders: { [key: string]: any } = {};
    this.#unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.docChanges().forEach((doc) => {
        const data = doc.doc.data();
        if (data.parent) data.parentId = data.parent.id;
        delete data.parent;
        folders[doc.doc.id] = { ...data, id: doc.doc.id };
      });
      this.#set({ ...folders });
    });
  }

  unsubscribe() {
    if (this.#unsubscribe) this.#unsubscribe();
  }
}

export default CollectionAccessor;
