import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ClerkProvider, SignedIn, SignedOut } from "@clerk/clerk-react";
import { RecoilRoot } from "recoil";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Auth from "./screens/Auth";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ClerkProvider
      publishableKey={
        "pk_live_Y2xlcmsuZGVtZXJzdGVjaC5jb20k"
      }
    >
      <RecoilRoot>
        <BrowserRouter>
          <SignedIn>
            <App />
          </SignedIn>
          <SignedOut>
            <Routes>
              <Route path="/sign-in" element={<Auth signIn />} />
              <Route path="/sign-up" element={<Auth signUp />} />
              <Route path="/*" element={<Navigate to="/sign-in" />} />
            </Routes>
          </SignedOut>
        </BrowserRouter>
      </RecoilRoot>
    </ClerkProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
